import styled from 'styled-components';

export const ProductoInformacionContenedor = styled.div`
  flex: 0 0 20%;
  padding: 10px 0;
  border-bottom: 1px solid #000;
  h1 {
    font-size: 19px;
    font-weight: bold;
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    column-count: 2;
    column-gap: 20px;
    height: 120px;
    li {
      font-size: 0.9em;
    }
  }
  @media only screen and (max-width: 600px) {
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: justify;
      font-size: 0.9em;

      @media only screen and (max-width: 600px) {
        font-size: 0.8em;
      }
    }
  }
`;
