const splitProductsByLength = (array: any[] | undefined, length: number) => {
  const chunks = [];
  let i = 0;

  if (!array) {
    return null;
  }

  const n = array.length;
  while (i < n) {
    chunks.push(array.slice(i, (i += length)));
  }
  return chunks;
};

export default splitProductsByLength;
