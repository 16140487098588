import React, { FC } from 'react';
import { TitleBorder, TitleContainer, TitleMain, TitleSubtitle } from './SectionTitle.styled';

export interface TitleProps {
  title: string;
  subTitle: string;
  subTitleColor?: string;
}

const SectionTitle: FC<TitleProps> = ({ title, subTitle, subTitleColor }) => {
  return (
    <TitleContainer key="main-title">
      <TitleSubtitle>{title}</TitleSubtitle>
      <TitleMain subTitleColor={subTitleColor}>{subTitle}</TitleMain>
      <TitleBorder />
    </TitleContainer>
  );
};

SectionTitle.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  subTitleColor: '#262626',
};

export default SectionTitle;
