import styled from 'styled-components';

export const FooterMenuContainer = styled.div`
  padding-top: 20px;

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    width: 85vw;
  }

  h3 {
    font-size: 1.4em;
    color: #fff;

    @media only screen and (max-width: 600px) {
      text-align: center;
      font-size: 1.2em;
    }
  }
`;

export const FooterMenuList = styled.ul`
  padding: 0;

  li {
    list-style: none;
    padding: 10px 5px;
    transition: 0.8s;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      text-align: center;
      padding: 7px 2px;
    }

    :hover {
      background-color: gold;
    }

    :hover span {
      color: #000;
    }

    span {
      color: #969696;
      font-size: 1.2em;
      transition: 0.8s;

      @media only screen and (max-width: 600px) {
        font-size: 1em;
      }
    }

    :not(:last-child) {
      border-bottom: 1px dashed #969696;
    }
  }
`;
