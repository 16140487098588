import styled from 'styled-components';
import img from '../../assets/images/footer/newsletter-bg.jpg';

interface Props {
  flexDirection: string;
}

export const FooterInformation = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: ${(p) => p.flexDirection};
  padding: 5% 0;
  max-width: 1320px;
  margin: auto;

  @media only screen and (max-width: 600px) {
    padding: 15% 0;
  }
`;

export const FooterBackground = styled.div`
  background: url('${img}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const FooterCopyright = styled.div`
  background-color: #000;
  padding: 1% 10%;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #969696;
  font-size: 14px;

  @media only screen and (max-width: 600px) {
    padding: 3% 0;
    font-size: 10px;
  }
`;
