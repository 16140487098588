import { Row } from 'antd';
import React, { FC } from 'react';
import img from '../../assets/images/supplement-process/process-bg.jpg';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import SubSectionContainer from '../../shared/components/SubSectionContainer/SubSectionContainer';
import SupplementProcessItem from './components/SupplementProcessItem';
import SupplementProcessQuery from './SupplementProcess.query';

const SupplementProcess: FC<AguilaProps> = (props) => {
  const supplementProcess = SupplementProcessQuery();
  const supplementProps = { ...props, subTitleColor: '#fff' };
  const { isMobile } = props;

  const items = supplementProcess.map((item: SupplementProcessData, i: number) => {
    return (
      <SupplementProcessItem
        key={item.description}
        imageSrc={item.src.childImageSharp.gatsbyImageData}
        imageDescription={item.description}
        isMobile={isMobile}
        delay={i}
      />
    );
  });

  return (
    <SubSectionContainer backgroundImage={img}>
      <SectionTitle {...supplementProps} />
      <Row style={{ minHeight: '24vh', padding: '20px', alignItems: 'center' }}>{items}</Row>
    </SubSectionContainer>
  );
};

export default SupplementProcess;

interface SupplementProcessData {
  description: string;
  src: any;
}
