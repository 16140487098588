import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';
import SectionContainer from '../../shared/components/SectionContainer/SectionContainer';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import { WelcomeContainer, WelcomeDetails } from './Welcome.styled';

const Welcome: FC<AguilaProps> = (props) => {
  const { isMobile } = props;

  const welcomeImageMobile = isMobile ? (
    <QueueAnim type="bottom" leaveReverse style={{ display: 'flex', flexDirection: 'row' }}>
      <div key="welcome-image" id="welcome-image" />
    </QueueAnim>
  ) : (
    ''
  );

  const welcomeImageDesktop = !isMobile ? (
    <QueueAnim type="right" leaveReverse style={{ display: 'flex', flexDirection: 'row', flex: '0 0 60%' }}>
      <div key="welcome-image" id="welcome-image" />
    </QueueAnim>
  ) : (
    ''
  );

  return (
    <SectionContainer id="section-welcome">
      <SectionTitle {...props} />
      <WelcomeContainer flexDirection={isMobile ? 'column' : 'row'} style={{ minHeight: isMobile ? '64vh' : '74vh' }}>
        {welcomeImageMobile}
        <QueueAnim
          type={isMobile ? 'bottom' : 'left'}
          leaveReverse
          delay={500}
          duration={1000}
          style={{ display: 'flex', flexDirection: 'row', flex: isMobile ? 1 : '0 0 40%', zIndex: -10 }}
        >
          <WelcomeDetails key="details">
            <h3 style={{ fontWeight: 100 }}>El cambio es tu decisión,</h3>
            <h2>
              <strong style={{ color: 'gold' }}>Águila Azteca Nutrition</strong>
            </h2>
            <h2>
              <strong>es tu solución.</strong>
            </h2>
            <p key="p">{` `}Todo parece imposible hasta que se hace, productos de alta calidad, resultados garantizados al mejor precio.</p>
            <button key="button" type="button">
              DETALLES DEL PRODUCTO
            </button>
          </WelcomeDetails>
        </QueueAnim>
        {welcomeImageDesktop}
      </WelcomeContainer>
    </SectionContainer>
  );
};

export default Welcome;
