import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel, Col, Row } from 'antd';
import 'antd/dist/antd.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import splitProductsByLength from '../../utils/splitProductsByLength';
import ProductListItem from './components/ProductListItem';

interface Props {
  productos: DetalleProducto[] | undefined;
  onChange: (producto: DetalleProducto) => void;
  isMobile: boolean;
}

export const ProductosLista: FC<Props> = ({ onChange, productos, isMobile }) => {
  const handleChange = (producto: DetalleProducto) => {
    onChange(producto);
  };

  const result =
    productos &&
    productos.reduce((resultArray: any[], item: DetalleProducto, index: number) => {
      const chunkIndex = Math.floor(index / 4);
      if (!resultArray[chunkIndex]) {
        // eslint-disable-next-line no-param-reassign
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

  const convertToChildren = (products: DetalleProducto[]) =>
    products.map((producto: DetalleProducto, i: number) => (
      <ProductListItem key={i.toString()} {...producto} index={i} onClick={() => handleChange(producto)} />
    ));

  if (isMobile) {
    const splitedProducts = splitProductsByLength(productos, 3);

    const childrenMobile = splitedProducts?.map((splitProduct, index) => {
      return (
        <div key={index.toString()}>
          <Row>
            {splitProduct.map((producto, i) => {
              return (
                <Col
                  key={i.toString()}
                  span={8}
                  onClick={() => handleChange(producto)}
                  style={{
                    cursor: 'pointer',
                    padding: '10px 10px',
                  }}
                >
                  <div className="product-hover product-mobile">
                    <GatsbyImage
                      key="product-image-list"
                      image={producto.src.childImageSharp.gatsbyImageData}
                      title={producto.nombreComercial}
                      alt={producto.nombreComercial}
                      style={{ flex: 1 }}
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      );
    });

    const props = {
      arrows: true,
      dots: false,
      prevArrow: <LeftOutlined />,
      nextArrow: <RightOutlined />,
    };

    return (
      <>
        <Carousel {...props}>{childrenMobile}</Carousel>
      </>
    );
  }

  const props = {
    arrows: true,
    dots: false,
    prevArrow: <LeftOutlined />,
    nextArrow: <RightOutlined />,
  };

  return (
    <Carousel style={{ width: '50vw', display: 'flex' }} {...props}>
      {result?.map((val, index: number) => {
        return (
          <div key={index.toString()} style={{ display: 'flex', flexDirection: 'row' }}>
            {convertToChildren(val)}
          </div>
        );
      })}
    </Carousel>
  );
};

export interface DetalleProducto {
  nombre: string;
  nombreComercial: string;
  tipo: string;
  grupo: string;
  src: any;
  calificacion: DetalleProductoCalificacion;
  descripcion: string[];
}

export interface DetalleProductoCalificacion {
  precio: string;
  valor: number;
  cantidad: number;
}
