import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import SectionContainer from '../../shared/components/SectionContainer/SectionContainer';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import img from '../../assets/images/about-us/luchadoraguila3.png';
import img2 from '../../assets/images/about-us/luchadoraguila4.png';
import { AboutUsContainer, AboutUsDetails } from './AboutUs.styled';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';

const AboutUs: FC<AguilaProps> = (props) => {
  const { isMobile } = props;

  const imagenesLuchadores = (
    <QueueAnim
      type="scaleBig"
      leaveReverse
      delay={500}
      duration={1000}
      style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flex: 1 }}
    >
      <picture key="luchador1">
        <source srcSet={img} />
        <img src={img} alt="Luchador Aguila Azteca" />
      </picture>
      <picture key="luchador2">
        <source srcSet={img2} />
        <img src={img2} alt="Luchador Aguila Azteca" />
      </picture>
    </QueueAnim>
  );

  return (
    <SectionContainer id="section-about-us">
      <SectionTitle {...props} />
      <AboutUsContainer style={{ minHeight: isMobile ? '60vh' : '74vh' }}>
        <AboutUsDetails>
          <QueueAnim type="bottom" leaveReverse delay={500} duration={1000} style={{ display: 'flex', flexDirection: 'column' }}>
            <p key="text1">
              La marca <span style={{ color: 'gold', fontWeight: 'bold' }}>Águila Azteca Nutrition</span> fue inspirada a base de un luchador
              profesional que su personaje es <span style={{ color: '#000', fontWeight: 'bold' }}>&quot;Águila Azteca&quot;</span> éste luchador en su
              momento fue patrocinado por varias marcas de fármacos, suplementos, gimnasios, y muchas marcas más...
            </p>
            {isMobile ? imagenesLuchadores : ''}
            <p key="text2">
              A base de <span style={{ color: '#000', fontWeight: 'bold' }}>su experiencia y testimonio de más de 12 años dentro del deporte</span>{' '}
              crea esta marca única inspirada en grandes marcas de nivel mundial, siendo como resultado un producto único con un precio inigualable y
              de calidad premium 💪
            </p>
          </QueueAnim>
        </AboutUsDetails>
        {!isMobile ? imagenesLuchadores : ''}
      </AboutUsContainer>
    </SectionContainer>
  );
};

export default AboutUs;
