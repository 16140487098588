import { Col } from 'antd';
import React, { FC } from 'react';

const ProductoSeleccionado: FC = ({ children }) => {
  return (
    <Col span={24} style={{ padding: '10px 60px', display: 'flex', alignItems: 'center' }}>
      {children}
    </Col>
  );
};

export default ProductoSeleccionado;
