import { Rate } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import { DetalleProductoCalificacion } from '../ProductosLista/ProductosLista';
import { ProductoTituloContenedor, ProductoTituloEncabezado, ProductoTituloRating } from './ProductoTitulo.styled';

interface Props {
  nombreComercial: string;
  calificacion: DetalleProductoCalificacion;
}

const ProductoTitulo: FC<Props> = ({ nombreComercial, calificacion }) => {
  return (
    <QueueAnim type="right" delay={300} leaveReverse component={ProductoTituloContenedor}>
      <ProductoTituloEncabezado key="encabezado">{nombreComercial}</ProductoTituloEncabezado>
      <ProductoTituloRating key="rating">
        <Rate disabled value={calificacion.valor} />
        <span>({calificacion.cantidad} Calificaciones de Clientes)</span> <br />
      </ProductoTituloRating>
      <h3 key="precio">
        Precio: <strong>{calificacion.precio}</strong>
      </h3>
    </QueueAnim>
  );
};

export default ProductoTitulo;
