import styled from 'styled-components';

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  @media only screen and (max-width: 600px) {
    z-index: 1;
    text-align: center;
    font-size: 1.2em;
    padding: 0 0 5px;
    margin-bottom: 10px;
  }
`;

export const TitleSubtitle = styled.h4`
  color: gold;
  font-style: italic;
  margin: 0;
  font-size: 1.5em;
  line-height: 1;
  @media only screen and (max-width: 600px) {
    text-align: center;
    font-size: 0.9em;
    z-index: -10;
  }
`;

export const TitleMain = styled.h1<Props>`
  color: ${(p) => p.subTitleColor};
  margin: 0;
  font-size: 2.5em;
  @media only screen and (max-width: 600px) {
    text-align: center;
    font-size: 1.2em;
    margin: 0 0 5px;
    z-index: -10;
  }
`;

export const TitleBorder = styled.span`
  border-bottom: 6px solid gold;
  width: 100px;
  @media only screen and (max-width: 600px) {
    border-bottom: 3px solid gold;
    z-index: -10;
  }
`;

interface Props {
  subTitleColor?: string;
}
