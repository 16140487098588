import React, { FC, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';

interface Props {
  fromNumber: number;
  toNumber: number;
}

const NumberAnimation: FC<Props> = (props) => {
  const [flip] = useState(false);
  const { fromNumber } = props;
  const { toNumber } = props;
  const { number } = useSpring({
    reset: false,
    reverse: flip,
    from: { number: fromNumber },
    number: toNumber,
    delay: 50,
    config: config.molasses,
  });

  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
};

export default NumberAnimation;
