import { useStaticQuery, graphql } from 'gatsby';

const ProductosQuery = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allProductsJson {
          nodes {
            tipo
            grupo
            nombreComercial
            nombre
            calificacion {
              valor
              cantidad
              precio
            }
            descripcion
            src {
              childImageSharp {
                gatsbyImageData(width: 500, placeholder: BLURRED)
              }
            }
          }
        }
      }
    `
  );

  return query.allProductsJson.nodes;
};

export default ProductosQuery;
