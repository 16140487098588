import { useStaticQuery, graphql } from 'gatsby';

const HeroQuery = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allHeroJson {
          nodes {
            description
            src {
              childImageSharp {
                gatsbyImageData(height: 1700, placeholder: BLURRED)
              }
            }
          }
        }
      }
    `
  );

  return query.allHeroJson.nodes;
};

export default HeroQuery;
