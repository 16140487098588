import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { HeroCarouselItemContainer } from './HeroCarouselItem.styled';

const HeroCarouselItem: FC<HeroItem> = ({ src, description }) => {
  return (
    <HeroCarouselItemContainer>
      <GatsbyImage
        className="hero-image"
        key="product-image-list"
        objectFit="fill"
        image={src.childImageSharp.gatsbyImageData}
        title={description}
        alt={description}
        style={{
          height: '100%',
          width: '100%',
          zIndex: -10,
        }}
      />
    </HeroCarouselItemContainer>
  );
};

export default HeroCarouselItem;

export interface HeroItem {
  src: any;
  description: string;
}
