import { Col, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import SectionContainer from '../../shared/components/SectionContainer/SectionContainer';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';
import AthletesQuery from './Authletes.query';
import AthletesItem from './components/AthletesItem';

export interface Athlete {
  src: any;
  description: string;
}

const Athletes: FC<AguilaProps> = (props) => {
  const { isMobile } = props;
  const athletesQuery = AthletesQuery();

  const children = athletesQuery.map((athlete: Athlete, index: number) => {
    return (
      <Col className="gutter-row" span={isMobile ? 12 : 6} key={index.toString()}>
        <QueueAnim type="scaleX" delay={300 * index} leaveReverse component={Row}>
          <AthletesItem key={index.toString()} {...athlete} />
        </QueueAnim>
      </Col>
    );
  });

  return (
    <SectionContainer id="section-athletes" {...props}>
      <SectionTitle {...props} />
      <Row style={{ minHeight: isMobile ? '55vh' : '78vh' }} gutter={[24, 24]}>
        {children}
      </Row>
    </SectionContainer>
  );
};

export default Athletes;
