import { Carousel } from 'antd';
import React, { FC } from 'react';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';
import HeroCarouselItem, { HeroItem } from './components/HeroCarouselItem';
import HeroQuery from './Hero.query';

const Hero: FC<AguilaProps> = (props) => {
  const { isMobile } = props;
  const heroes = HeroQuery();

  const items = heroes.map((heroItem: HeroItem, i: number) => {
    const heroProps = { ...heroItem };
    return <HeroCarouselItem key={i.toString()} {...heroProps} />;
  });
  return (
    <section id="section-hero">
      <Carousel
        key="carousel"
        autoplay
        autoplaySpeed={5000}
        effect={isMobile ? 'scrollx' : 'fade'}
        style={{ padding: '0', zIndex: -10 }}
        dots={false}
        pauseOnDotsHover={false}
      >
        {items}
      </Carousel>
    </section>
  );
};

export default Hero;
