import styled from 'styled-components';
import img from '../../assets/images/products/allproductos.jpeg';

interface Props {
  flexDirection: string;
}

export const WelcomeContainer = styled.div<Props>`
  display: flex;
  flex-direction: ${(p) => p.flexDirection};
  justify-content: center;
  width: 100%;

  #welcome-image {
    background: url('${img}');
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @media only screen and (max-width: 600px) {
      height: 30vh;
      width: 100%;
    }
  }
`;

export const WelcomeDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  @media only screen and (max-width: 600px) {
    padding: 2% 0;
  }

  h3,
  h2 {
    font-size: 2em;
    margin-bottom: 0;
    line-height: 1.4;
    @media only screen and (max-width: 600px) {
      font-size: 1.3em;
      text-align: center;
    }
  }

  h2 {
    font-size: 3.6em;
    @media only screen and (max-width: 600px) {
      font-size: 1.3em;
      text-align: center;
    }
  }

  p {
    color: #a3a3a3;
    font-size: 1.2em;
    @media only screen and (max-width: 600px) {
      font-size: 1em;
      text-align: justify;
      padding: 2% 0 0 0;
    }
  }

  button {
    width: 260px;
    padding: 15px 20px;
    background: linear-gradient(53deg, rgba(2, 0, 36, 1) 0%, rgba(184, 138, 0, 1) 0%, rgba(251, 221, 1, 1) 100%, rgba(255, 255, 255, 1) 100%);
    border: 2px solid #f7cc4c;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: 0.8s;
    opacity: 0.8;
    font-size: 1.1em;

    @media only screen and (max-width: 600px) {
      padding: 5% 5px;
      font-size: 1.2em;
      width: 100%;
    }

    :hover {
      opacity: 1;
      background: #fbdd01;
    }
  }
`;
