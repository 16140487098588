import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import { IsMobileProps } from '../../../../shared/constants/interfaces/is-mobile-props';
import { scrollToSection } from '../../../../utils';
import { FooterMenuContainer, FooterMenuList } from './FooterMenu.styled';

const FooterMenu: FC<IsMobileProps> = (props) => {
  const { isMobile } = props;

  const footerMenu = (
    <FooterMenuContainer key="footer-menu">
      <h3>Menú Principal</h3>
      <FooterMenuList>
        <li onClick={() => scrollToSection('section-welcome')} aria-hidden="true">
          <span>Inicio</span>
        </li>
        <li onClick={() => scrollToSection('section-about-us')} aria-hidden="true">
          <span>Sobre Nosotros</span>
        </li>
        <li onClick={() => scrollToSection('section-eventos')} aria-hidden="true">
          <span>Eventos</span>
        </li>
        <li onClick={() => scrollToSection('section-productos')} aria-hidden="true">
          <span>Productos</span>
        </li>
      </FooterMenuList>
    </FooterMenuContainer>
  );
  return isMobile ? (
    <QueueAnim type="left" leaveReverse delay={500} duration={1000}>
      {footerMenu}
    </QueueAnim>
  ) : (
    <div>{footerMenu}</div>
  );
};

export default FooterMenu;
