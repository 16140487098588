import { Card } from 'antd';
import { GatsbyImage } from 'gatsby-plugin-image';
import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import { DetalleProducto } from '../ProductosLista';
import { ProductListItemContainer } from './ProductListItem.styled';

interface DetallleProductoClick extends DetalleProducto {
  onClick: () => void;
  index: number;
}

const ProductListItem: FC<DetallleProductoClick> = ({ src, nombreComercial, onClick, index }) => {
  return (
    <QueueAnim delay={300 * index} type="left" key="block" component={ProductListItemContainer} onClick={onClick}>
      <Card
        key="card"
        hoverable
        bodyStyle={{
          padding: 0,
          height: '150px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <GatsbyImage
          key="product-image-list"
          objectFit="contain"
          image={src.childImageSharp.gatsbyImageData}
          title={nombreComercial}
          alt={nombreComercial}
          style={{
            height: '100%',
          }}
        />
      </Card>
    </QueueAnim>
  );
};

export default ProductListItem;
