import { Col, ColProps } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

export const SupplementProcessItemContainer: FC<ColProps> = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  border-right: rgb(252, 225, 2);
  border-right-style: dashed;
  border-right-width: medium;

  .flex-center-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .flex-center-column > .gatsby-image-wrapper {
    transition: transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .flex-center-column .gatsby-image-wrapper:hover {
    transform: translateY(-4px) !important;
  }

  @media only screen and (max-width: 600px) {
    border-right: none;
    padding-bottom: 3vh;
  }

  :nth-child(4) {
    border-right: none;
  }

  p {
    color: #fff;
    font-size: 1.7em;
    margin: 10px 0 0;
    text-align: center;

    @media only screen and (max-width: 600px) {
      font-size: 1em;
    }
  }
`;
