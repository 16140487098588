import styled from 'styled-components';

export const FooterSubscribeFormBlackContainer = styled.div`
  display: flex;
  flex: 0 0 60%;
  flex-direction: column;
  background-color: #fff;
  padding: 30px 40px;

  @media only screen and (max-width: 600px) {
    padding: 20px 30px;
  }
`;

export const FooterSubscribeFormGoldContainer = styled.div`
  background-color: gold;
  padding: 30px 40px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 600px) {
    padding: 20px 30px;
  }

  span {
    font-size: 1.1em;
  }

  img {
    position: absolute;
    width: 150px;
    right: 0;
    top: -70px;

    @media only screen and (max-width: 600px) {
      width: 110px;
      top: -20px;
      right: 10px;
    }
  }
`;
