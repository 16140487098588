import React, { FC } from 'react';
import 'antd/dist/antd.css';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import SubSectionContainer from '../../shared/components/SubSectionContainer/SubSectionContainer';
import img from '../../assets/images/banner/image.jpeg';

export const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Banner: FC<AguilaProps> = (props) => {
  const titleProps = { ...props, subTitleColor: '#fff' };

  return (
    <SubSectionContainer backgroundImage={img}>
      <SectionTitle {...titleProps} />
      <BannerContainer>
        <ReactPlayer url="https://fb.watch/aL0C7phJHN/" controls={false} autoPlay playing loop muted playsinline />
      </BannerContainer>
    </SubSectionContainer>
  );
};

export default Banner;
