import { Col, ColProps } from 'antd';
import { FC } from 'react';
import styled from 'styled-components';

export const TextItemContainer = styled.div`
  text-align: left;
  color: white;
  margin-left: 15px;

  div {
    color: rgb(252, 225, 2);
  }

  @media only screen and (max-width: 600px) {
    flex: 0 0 60%;
    margin-left: 0;
  }
`;

export const StatisticsItemContainer: FC<ColProps> = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;

    div {
      font-size: 1.7em;

      @media only screen and (max-width: 600px) {
        font-size: 1.5em;
        text-align: left;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 10px 0;
    justify-content: center;
  }

  p {
    color: #fff;
    font-size: 0.6em;
    margin: 0;
    line-height: 1.3;

    @media only screen and (max-width: 600px) {
      font-size: 0.7em;
    }
  }
`;
