import QueueAnim from 'rc-queue-anim';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { StatisticsItemContainer, TextItemContainer } from './StatisticsItem.styled';
import NumberAnimation from '../../../shared/components/NumberAnimation';

interface Props {
  isMobile: boolean;
  src: IGatsbyImageData;
  description: string;
  fromNumber: number;
  toNumber: number;
}

const StatisticsItem: FC<Props> = ({ src, description, fromNumber, toNumber, isMobile }) => {
  const imageStyles = isMobile ? { width: '60px', height: '60px', flex: '0 0 40%' } : { width: '70px', height: '70px' };
  return (
    <StatisticsItemContainer span={isMobile ? 24 : 6}>
      <QueueAnim
        type="bottom"
        key="title"
        ease="easeOutQuart"
        leaveReverse
        className="flex-center-column animation-container"
        style={{ width: isMobile ? '100%' : 'auto' }}
      >
        <GatsbyImage
          key="product-image-list"
          image={src}
          title={description}
          alt={description}
          imgStyle={{ objectFit: 'contain' }}
          style={imageStyles}
        />
        <TextItemContainer>
          <NumberAnimation fromNumber={fromNumber} toNumber={toNumber} />
          <p key={description}>{description}</p>
        </TextItemContainer>
      </QueueAnim>
    </StatisticsItemContainer>
  );
};

export default StatisticsItem;
