import React, { FC } from 'react';
import styled from 'styled-components';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

const Container = styled.section`
  display: flex;
  min-height: 100vh;
  max-width: 1320px;
  align-items: center;
  margin: auto;
  padding: 45px 0;

  @media only screen and (max-width: 600px) {
    z-index: -100;
    padding: 12% 10px !important;
    min-height: 90vh;
  }
`;

interface SectionProps {
  id?: string;
}

const SectionContainer: FC<SectionProps> = ({ children, id }) => {
  return (
    <Container id={id}>
      <OverPack playScale={0.4} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: '0 30px' }}>
        {children}
      </OverPack>
    </Container>
  );
};

export default SectionContainer;

SectionContainer.defaultProps = {
  id: '',
};
