import { useStaticQuery, graphql } from 'gatsby';

const StatisticsQuery = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allStatisticsJson {
          nodes {
            description
            fromNumber
            toNumber
            src {
              childImageSharp {
                gatsbyImageData(height: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    `
  );

  return query.allStatisticsJson.nodes;
};

export default StatisticsQuery;
