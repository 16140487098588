import React, { FC } from 'react';
import WhatsappButton from '../../../../shared/components/WhatsappButton/WhatsappButton';
import { ProductoInformacionContenedor } from './ProductoInformacion.styled';

interface Props {
  descripcion: string[];
}

const ProductoInformacion: FC<Props> = ({ descripcion }) => {
  const beneficio = descripcion.map((descr, i) => <li key={i.toString()}> {descr} </li>);
  return (
    <ProductoInformacionContenedor>
      <h1 key="beneficios"> BENEFICIOS: </h1>
      <ul key="beneficios-list"> {beneficio} </ul>
      <WhatsappButton key="whatsapp" />
    </ProductoInformacionContenedor>
  );
};

export default ProductoInformacion;
