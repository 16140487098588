import { Col, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import SectionContainer from '../../shared/components/SectionContainer/SectionContainer';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';
import EventsItem from './components/EventsItem';
import EventosQuery from './Eventos.query';

export interface Events {
  src: any;
  title: string;
  subtitle: string;
  description: string;
}

const Eventos: FC<AguilaProps> = (props) => {
  const { isMobile } = props;
  const productos = EventosQuery();

  const children = productos.map((event: Events, i: number) => (
    <Col key={i.toString()} className="image-col" span={isMobile ? 24 : 8}>
      <QueueAnim type="alpha" delay={500 * i} key="block" component={Row} className="contenedor-eventos-image">
        <EventsItem key={i.toString()} {...event} />
      </QueueAnim>
    </Col>
  ));

  return (
    <SectionContainer id="section-eventos" {...props}>
      <SectionTitle {...props} />
      <Row style={{ minHeight: isMobile ? '55vh' : '78vh', alignItems: 'center' }} gutter={12}>
        {children}
      </Row>
    </SectionContainer>
  );
};

export default Eventos;
