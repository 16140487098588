import { Card } from 'antd';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { Athlete } from '../Athletes';
import { AthletesItemContainer } from './AthletesItem.styled';

const AthletesItem: FC<Athlete> = ({ src, description }) => {
  return (
    <>
      <AthletesItemContainer>
        <Card hoverable bodyStyle={{ padding: 0 }}>
          <GatsbyImage
            className="athlete-image"
            key="athlete-image-list"
            objectFit="contain"
            image={src.childImageSharp.gatsbyImageData}
            title={description}
            alt={description}
            style={{
              height: '100%',
            }}
          />
        </Card>
      </AthletesItemContainer>
    </>
  );
};

export default AthletesItem;
