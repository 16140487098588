import styled from 'styled-components';

export const TestimonialsCarouselItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    padding: 0 5%;
  }

  span {
    color: #fff;
    text-align: center;
    font-size: 1.2em;
    @media only screen and (max-width: 600px) {
      font-size: 0.8em;
    }
  }
`;

export const TestimonialsCarouselItemPerson = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;

  .testimonial-image {
    border-radius: 50%;
    border: 2px solid gold;
  }
`;

export const TestimonialCarouselItemPersonDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  h4 {
    font-size: 2em;
    color: #fff;
    margin: 0;
    @media only screen and (max-width: 600px) {
      font-size: 1em;
    }
  }

  p {
    color: gold;
    font-size: 1.5em;
    margin: 0;
    @media only screen and (max-width: 600px) {
      font-size: 0.9em;
    }
  }
`;
