import QueueAnim from 'rc-queue-anim';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { SupplementProcessItemContainer } from './SupplementProcessItem.styled';

interface Props {
  isMobile: boolean;
  imageSrc: IGatsbyImageData;
  imageDescription: string;
  delay: number;
}

const SupplementProcessItem: FC<Props> = (props) => {
  const { imageSrc, imageDescription, isMobile, delay } = props;

  return (
    <SupplementProcessItemContainer span={isMobile ? 12 : 6}>
      <QueueAnim type="bottom" key="title" ease="easeOutQuart" delay={200 * delay} leaveReverse className="flex-center-column">
        <GatsbyImage
          key="product-image-list"
          image={imageSrc}
          title={imageDescription}
          alt={imageDescription}
          imgStyle={{ objectFit: 'contain' }}
          style={{ height: isMobile ? '40px' : '80px' }}
          className="supplement-item-image"
        />
        <p key={imageDescription}>{imageDescription}</p>
      </QueueAnim>
    </SupplementProcessItemContainer>
  );
};

export default SupplementProcessItem;
