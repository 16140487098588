import React, { FC } from 'react';
import styled from 'styled-components';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

const SubSection = styled.section<Props>`
  padding: 2% 0;
  min-height: 43vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(p) => p.backgroundImage});
  background-size: cover;
  background-position: center center;

  @media only screen and (max-width: 600px) {
    padding: 5% 0 !important;
    min-height: 320px;
  }
`;

interface Props {
  backgroundImage: string;
}

const SubSectionContainer: FC<Props> = ({ children, backgroundImage }) => {
  return (
    <SubSection backgroundImage={backgroundImage}>
      <OverPack
        playScale={0.4}
        style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', maxWidth: '1320px', margin: 'auto', padding: '0 30px' }}
      >
        {children}
      </OverPack>
    </SubSection>
  );
};

export default SubSectionContainer;
