import styled from 'styled-components';

export const ProductoTituloContenedor = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #000;
  flex: '0 0 15%';

  h3 {
    color: green;
    font-size: 1.3em;
  }
`;

export const ProductoTituloEncabezado = styled.h2`
  text-align: left;
  font-size: 1.5em;
  margin: 0;
  height: 70px;
  display: flex;
  align-items: end;
  @media only screen and (max-width: 600px) {
    text-align: center;
    font-size: 1.5em;
  }
`;

export const ProductoTituloRating = styled.div`
  display: block;
  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      margin-left: 3px;
      font-size: 0.8em;
    }
    .ant-rate-star {
      margin-right: 2px;
    }
  }
`;
