import styled from 'styled-components';

export const ProductListItemContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 23%;
  margin: 10px 10px 10px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.7s;
  cursor: pointer;

  :hover {
    transform: translate(0px, -5px) !important;
    box-shadow: 10px 10px 64px -8px rgb(51 51 51 / 34%);
  }
`;
