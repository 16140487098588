import styled from 'styled-components';

export const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  img {
    height: 60vh;

    @media only screen and (max-width: 600px) {
      width: 30vw;
      height: auto;
    }
  }
`;

export const AboutUsDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2vh 5vw;
  flex: 0 0 50%;

  p {
    font-size: 1.3em;
    text-align: justify;

    @media only screen and (max-width: 600px) {
      font-size: 0.9em;
    }
  }
`;
