import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FC, useState } from 'react';
import logo from '../../assets/images/logo/logo.png';
import WhatsappButton from '../../shared/components/WhatsappButton/WhatsappButton';
import { scrollToSection } from '../../utils';
import './Navbar.scss';

const Navbar: FC = ({ children }) => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const handleScrollToSection = (sectionName: string) => {
    handleClick();
    setTimeout(() => {
      scrollToSection(sectionName);
    }, 200);
  };

  const navBarStyles = {
    overflow: click ? 'hidden' : 'visible',
    height: click ? '100vh' : 'auto',
  };

  return (
    <div style={navBarStyles}>
      <nav className="navbar">
        <div className="navbar-logo" aria-hidden="true">
          <img className="logo" key="logo" src={logo} alt="logo" />
        </div>
        <div className="menu-icon">
          <Button type="link" onClick={handleClick} aria-hidden="true">
            <MenuOutlined twoToneColor="#eb2f96" style={{ fontSize: '2em', color: '#000' }} />
          </Button>
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className="nav-item nav-links" onClick={() => handleScrollToSection('section-welcome')} aria-hidden="true">
            Inicio
          </li>
          <li className="nav-item nav-links" onClick={() => handleScrollToSection('section-about-us')} aria-hidden="true">
            Sobre Nosotros
          </li>
          <li className="nav-item nav-links" onClick={() => handleScrollToSection('section-eventos')} aria-hidden="true">
            Eventos
          </li>
          <li className="nav-item nav-links" onClick={() => handleScrollToSection('section-productos')} aria-hidden="true">
            Productos
          </li>
          <li className="nav-item nav-links" aria-hidden="true">
            <WhatsappButton />
          </li>
        </ul>
      </nav>
      <div>{children}</div>
    </div>
  );
};

export default Navbar;
