import React, { FC } from 'react';
import image from '../../../assets/images/icono-Whatsapp.png';
import './WhatsApp.scss';

const WhatsApp: FC = () => {
  return (
    <div className="icon-bar">
      <a className="active shadow" href="https://wa.me/528128731809?text=%20Hola%20quisiera%20realizar%20un%20pedido." target="_blanc">
        {' '}
        <span>
          <img className="icon" src={image} alt="iconohatsapp" />
        </span>
      </a>
    </div>
  );
};

export default WhatsApp;
