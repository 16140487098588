import { FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Tooltip } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';

const CustomButton: FC<ButtonProps> = styled(Button)`
  margin: 2px 4px;
  background-color: #ffe404;
  border-color: #b88a00;
  color: #000;

  :hover {
    background-color: #b88a00;
    color: #fff;
  }
`;

interface Props {
  isMobile: boolean;
}

const FooterSocial: FC<Props> = (props) => {
  const { isMobile } = props;
  const buttonProps: ButtonProps = {
    type: 'primary',
    shape: 'circle',
    size: isMobile ? 'large' : 'middle',
  };

  const socialContainerStyles: CSSProperties = { display: 'flex', flexDirection: 'row' };

  return isMobile ? (
    <QueueAnim type="bottom" leaveReverse style={socialContainerStyles}>
      <Tooltip title="Síguenos en Twitter!" key="twitter">
        <CustomButton key="button1" {...buttonProps} icon={<TwitterOutlined />} />
      </Tooltip>
      <Tooltip title="Síguenos en Facebook!" key="facebook">
        <CustomButton key="button2" {...buttonProps} icon={<FacebookOutlined />} />
      </Tooltip>
      <Tooltip title="Síguenos en Instagram!" key="instagram">
        <CustomButton key="button3" {...buttonProps} icon={<InstagramOutlined />} />
      </Tooltip>
      <Tooltip title="Síguenos en Youtube!" key="youtube">
        <CustomButton key="button4" {...buttonProps} icon={<YoutubeOutlined />} />
      </Tooltip>
    </QueueAnim>
  ) : (
    <div style={socialContainerStyles}>
      <Tooltip title="Síguenos en Twitter!" key="twitter">
        <CustomButton key="button1" {...buttonProps} icon={<TwitterOutlined />} />
      </Tooltip>
      <Tooltip title="Síguenos en Facebook!" key="facebook">
        <CustomButton key="button2" {...buttonProps} icon={<FacebookOutlined />} />
      </Tooltip>
      <Tooltip title="Síguenos en Instagram!" key="instagram">
        <CustomButton key="button3" {...buttonProps} icon={<InstagramOutlined />} />
      </Tooltip>
      <Tooltip title="Síguenos en Youtube!" key="youtube">
        <CustomButton key="button4" {...buttonProps} icon={<YoutubeOutlined />} />
      </Tooltip>
    </div>
  );
};

export default FooterSocial;
