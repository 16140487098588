import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
// import Icon from '../../../assets/images/testimonials/testimonials.svg';
import {
  TestimonialCarouselItemPersonDescription,
  TestimonialsCarouselItemContainer,
  TestimonialsCarouselItemPerson,
} from './TestimonialsCarouselItem.styled';

const TestimonialsCarouselItem: FC<TestimonialsItem> = ({ src, description, name, profesion, isMobile }) => {
  return (
    <TestimonialsCarouselItemContainer>
      <div style={{ height: isMobile ? '130px' : '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span>{description}</span>
      </div>
      <TestimonialsCarouselItemPerson>
        <GatsbyImage
          className="testimonial-image"
          key="product-image-list"
          image={src.childImageSharp.gatsbyImageData}
          title={name}
          alt={name}
          imgStyle={{ borderRadius: '100%' }}
          style={{
            height: isMobile ? '55px' : '100px',
            width: isMobile ? '55px' : '100px',
          }}
        />
        <TestimonialCarouselItemPersonDescription>
          <h4 key="h4">{name}</h4>
          <p key="p">{profesion}</p>
        </TestimonialCarouselItemPersonDescription>
      </TestimonialsCarouselItemPerson>
    </TestimonialsCarouselItemContainer>
  );
};

export default TestimonialsCarouselItem;

export interface TestimonialsItem {
  src: any;
  description: string;
  name: string;
  profesion: string;
  isMobile: boolean;
}
