import { Carousel } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import img from '../../assets/images/testimonials/testimonial-bg.jpeg';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import SubSectionContainer from '../../shared/components/SubSectionContainer/SubSectionContainer';
import TestimonialsCarouselItem, { TestimonialsItem } from './components/TestimonialsCarouselItem';
import TestimonialsQuery from './Testimonials.query';
import './Testimonials.scss';

const Testimonials: FC<AguilaProps> = (props) => {
  const { isMobile } = props;
  const testimonialTitleProps = { ...props, subTitleColor: '#fff' };
  const testimonials = TestimonialsQuery();

  const items = testimonials.map((testimonialsItem: TestimonialsItem, i: number) => {
    const testimonialsProps = { ...testimonialsItem, isMobile };
    return <TestimonialsCarouselItem key={i.toString()} {...testimonialsProps} />;
  });

  return (
    <SubSectionContainer backgroundImage={img}>
      <SectionTitle {...testimonialTitleProps} />
      <QueueAnim type="scale" leaveReverse style={{ minHeight: '26vh' }}>
        <Carousel key="carousel" autoplay autoplaySpeed={8000} effect={isMobile ? 'scrollx' : 'fade'}>
          {items}
        </Carousel>
      </QueueAnim>
    </SubSectionContainer>
  );
};

export default Testimonials;
