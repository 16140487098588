import { Col, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import img from '../../assets/images/certifications/backgroundwhite.jpeg';
import cofepris from '../../assets/images/certifications/cofepris.svg';
import condusef from '../../assets/images/certifications/condusef_nuevo.svg';
import goDaddy from '../../assets/images/certifications/godaddy_nuevo.svg';
import ssl from '../../assets/images/certifications/ssl_nuevo.svg';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import SubSectionContainer from '../../shared/components/SubSectionContainer/SubSectionContainer';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';

const Certificacion: FC<AguilaProps> = (props) => {
  return (
    <SubSectionContainer backgroundImage={img}>
      <SectionTitle {...props} />
      <QueueAnim type="right" ease="easeOutQuart" leaveReverse style={{ minHeight: '24vh', alignItems: 'center' }} component={Row}>
        <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'center' }} key="cofepris">
          <img key="cofepris" src={cofepris} width="150" alt="cofepris" />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'center' }} key="condusef">
          <img key="condusef" src={condusef} width="150" alt="condusef" />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'center' }} key="goDaddy">
          <img key="goDaddy" src={goDaddy} width="250" alt="goDaddy" />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'center' }} key="ssl">
          <img key="ssl" src={ssl} width="130" alt="ssl" />
        </Col>
      </QueueAnim>
    </SubSectionContainer>
  );
};

export default Certificacion;
