import { enquireScreen } from 'enquire-js';
import { PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import AboutUs from '../../components/AboutUs/AboutUs';
import Athletes from '../../components/Athletes/Athletes';
import Banner from '../../components/Banner/Banner';
import Certificacion from '../../components/Certificacion/Certificacion';
import Eventos from '../../components/Eventos/Eventos';
import Footer from '../../components/Footer/Footer';
import Hero from '../../components/Hero/Hero';
import Navbar from '../../components/Menu/Navbar';
import Productos from '../../components/Productos/Productos';
import Statistics from '../../components/Statistics/Statistics';
import SupplementProcess from '../../components/SupplementProcess/SupplementProcess';
import Testimonials from '../../components/Testimonials/Testimonials';
import Welcome from '../../components/Welcome/Welcome';
import WhatsApp from '../../shared/components/WhatsApp/WhatsApp';

const LandingPage: FC<PageProps> = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    enquireScreen((mobile: boolean) => setIsMobile(mobile));
  }, []);

  return (
    <>
      {/* <Navbar /> */}
      <Navbar>
        <WhatsApp />
        <Hero isMobile={isMobile} title="s" subTitle="s" />
        <Welcome isMobile={isMobile} title="Águila Azteca Nutrition" subTitle="La Marca de Otro Nivel" />
        <Testimonials isMobile={isMobile} title="Testimonios" subTitle="Nuestros Clientes" />
        <Athletes isMobile={isMobile} title="Atletas" subTitle="Nuestros Atletas nos Avalan" />
        <AboutUs isMobile={isMobile} title="Acerca de Nosotros" subTitle="El Origen" />
        <SupplementProcess isMobile={isMobile} title="Increíble" subTitle="Proceso de Suplemento" />
        <Eventos isMobile={isMobile} title="Presente" subTitle="En Los Mejores Eventos a Nivel Nacional" />
        <Statistics isMobile={isMobile} title="Nuestros Números" subTitle="De Otro Nivel" />
        <Productos {...props} isMobile={isMobile} title="Nuestros Productos" subTitle="Quema Grasa con Águila" />
        <Banner isMobile={isMobile} title="Video" subTitle="Águila Azteca Nutrition" />
        <Certificacion isMobile={isMobile} title="Certificaciones" subTitle="Sientete Confiado con Nosotros" />
        <Footer isMobile={isMobile} />
      </Navbar>
    </>
  );
};

export default LandingPage;
