import WhatsAppOutlined from '@ant-design/icons/lib/icons/WhatsAppOutlined';
import { Button, ButtonProps } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';

const CustomButton: FC<ButtonProps> = styled(Button)`
  background-color: #4dc247;
  color: #fff;
  bordercolor: #4dc247;
  font-size: 1.5em;
  height: 50px !important;
  transition: 0.8s;
  z-index: -10;

  :hover {
    background-color: #169d25;
  }
`;

const WhatsappButton: FC = () => {
  const suffix = (
    <WhatsAppOutlined
      style={{
        fontSize: 20,
      }}
    />
  );

  return (
    <a href="https://wa.me/528128731809?text=%20Hola%20quisiera%20realizar%20un%20pedido." target="_blank" rel="noreferrer">
      <CustomButton type="primary" shape="round" icon={suffix} size="large">
        ORDENA YA
      </CustomButton>
    </a>
  );
};

export default WhatsappButton;
