import { CopyrightOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import React, { FC } from 'react';
import { CSSProperties } from 'styled-components';
import logo from '../../assets/images/logo/logo.png';
import { IsMobileProps } from '../../shared/constants/interfaces/is-mobile-props';
import WhatsappButton from '../../shared/components/WhatsappButton/WhatsappButton';
import FooterMenu from './components/FooterMenu/FooterMenu';
import FooterSocial from './components/FooterSocial/FooterSocial';
import FooterSubscribeForm from './components/FooterSubscribeForm/FooterSubscribeForm';
import { FooterBackground, FooterCopyright, FooterInformation } from './Footer.styled';

const Footer: FC<IsMobileProps> = (props) => {
  const { isMobile } = props;

  const FooterFormMobile = isMobile ? (
    <>
      <FooterSubscribeForm {...props} />
      <FooterMenu {...props} />
    </>
  ) : (
    ''
  );

  const FooterFormDesktop = !isMobile ? (
    <QueueAnim
      type="bottom"
      leaveReverse
      duration={1000}
      style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: '5%', borderLeft: '1px solid #fff' }}
    >
      <FooterSubscribeForm key="form-desktop" {...props} />
      <FooterMenu {...props} key="footer-menu" />
    </QueueAnim>
  ) : (
    ''
  );

  const footerAboutStyles: CSSProperties = isMobile
    ? { flex: '1', display: 'flex', flexDirection: 'column', margin: 'auto', width: '85vw', justifyContent: 'center', alignItems: 'center' }
    : { flex: '0 0 30%', display: 'flex', flexDirection: 'column', paddingRight: '5%' };

  return (
    <footer style={{ display: 'flex', flexDirection: 'column' }}>
      <FooterBackground>
        <OverPack
          playScale={0.4}
          style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', minHeight: isMobile ? '110vh' : '55vh' }}
        >
          <FooterInformation key="info" flexDirection={isMobile ? 'column' : 'row'}>
            {FooterFormMobile}
            <QueueAnim type="bottom" leaveReverse duration={1000} style={footerAboutStyles}>
              <img key="logo-aguila" src={logo} alt="Logo Águila Azteca" style={{ width: isMobile ? '60vw' : '15vw' }} />
              <p key="footer-texto" style={{ color: '#969696', textAlign: isMobile ? 'center' : 'justify' }}>
                Somos una marca de suplementos deportivos enfocados y comprometidos al 100% para que alcances tus metas personales en el menor tiempo
                posible, recuerda que nuestros productos estan diseñados para cualquier tipo de atleta y contamos con excelentes referencias de
                nuestros clientes.
              </p>
              <FooterSocial key="footer-social" isMobile={isMobile} />
              {isMobile ? <br /> : <hr />}
              <WhatsappButton key="footer-whatsapp" />
            </QueueAnim>
            {FooterFormDesktop}
          </FooterInformation>
        </OverPack>
      </FooterBackground>
      <FooterCopyright>
        Copyright <CopyrightOutlined /> &nbsp; 2021 Developers House
      </FooterCopyright>
    </footer>
  );
};

export default Footer;
