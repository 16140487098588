import { ArrowRightOutlined, CommentOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import QueueAnim from 'rc-queue-anim';
import React, { FC } from 'react';
import img from '../../../../assets/images/footer/luchadorsito.png';
import { FooterSubscribeFormBlackContainer, FooterSubscribeFormGoldContainer } from './FooterSubscribeForm.styled';

const { Search } = Input;

interface Props {
  isMobile: boolean;
}

const FooterSubscribeForm: FC<Props> = (props) => {
  const { isMobile } = props;

  const suffix = (
    <ArrowRightOutlined
      style={{
        fontSize: 16,
        color: '#000',
      }}
    />
  );

  const subscribeButton = isMobile ? (
    <Space direction="vertical">
      <Input size="large" placeholder="Correo Electrónico" />
      <Button block type="primary" style={{ background: 'gold', borderColor: 'yellow', color: '#000', height: '40px' }} icon={suffix}>
        SUBSCRIBIRSE
      </Button>
    </Space>
  ) : (
    <Space direction="vertical">
      <Search
        suffix={suffix}
        placeholder="Correo Electrónico..."
        enterButton="SUBSCRIBIRSE"
        color="gold"
        type="button"
        style={{ background: 'gold' }}
      />
    </Space>
  );

  return isMobile ? (
    <QueueAnim
      type="left"
      leaveReverse
      delay={500}
      duration={1000}
      style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: isMobile ? '85%' : '100%', margin: isMobile ? 'auto' : 0 }}
    >
      <FooterSubscribeFormBlackContainer key="footer-black">
        <h3 style={{ color: '#000', textAlign: isMobile ? 'center' : 'start' }}>¡SUBSCRÍBETE!</h3>
        {subscribeButton}
      </FooterSubscribeFormBlackContainer>
      <FooterSubscribeFormGoldContainer key="footer-gold">
        <CommentOutlined style={{ fontSize: '2em' }} />
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
          <span>¿Dudas?</span>
          <span>¿Preguntas?</span>
        </div>
        <img src={img} alt="Luchador" />
      </FooterSubscribeFormGoldContainer>
    </QueueAnim>
  ) : (
    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: isMobile ? '85%' : '100%', margin: isMobile ? 'auto' : 0 }}>
      <FooterSubscribeFormBlackContainer>
        <h3 style={{ color: '#000', textAlign: isMobile ? 'center' : 'start' }}>¡SUBSCRÍBETE!</h3>
        {subscribeButton}
      </FooterSubscribeFormBlackContainer>
      <FooterSubscribeFormGoldContainer>
        <CommentOutlined style={{ fontSize: '2em' }} />
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
          <span>¿Dudas?</span>
          <span>¿Preguntas?</span>
        </div>
        <img src={img} alt="Luchador" />
      </FooterSubscribeFormGoldContainer>
    </div>
  );
};

export default FooterSubscribeForm;
