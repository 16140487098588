import styled from 'styled-components';

export const AthletesItemContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s;
  cursor: pointer;

  :hover {
    transform: translate(0px, -10px) !important;
    box-shadow: 10px 10px 64px -8px rgb(51 51 51 / 34%);
  }
`;
