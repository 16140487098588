import { useStaticQuery, graphql } from 'gatsby';

const SupplementProcessQuery = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allSupplementProcessJson {
          nodes {
            description
            src {
              childImageSharp {
                gatsbyImageData(height: 80, placeholder: BLURRED)
              }
            }
          }
        }
      }
    `
  );

  return query.allSupplementProcessJson.nodes;
};

export default SupplementProcessQuery;
