import { Col, Row } from 'antd';
import { GatsbyImage } from 'gatsby-plugin-image';
import QueueAnim from 'rc-queue-anim';
import React, { FC, useEffect, useState } from 'react';
import SectionContainer from '../../shared/components/SectionContainer/SectionContainer';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';
import ProductoInformacion from './components/ProductoInformacion/ProductoInformacion';
import ProductoSeleccionado from './components/ProductoSeleccionado/ProductoSeleccionado';
import { DetalleProducto, ProductosLista } from './components/ProductosLista/ProductosLista';
import ProductoTitulo from './components/ProductoTitulo/ProductoTitulo';
import ProductosQuery from './Productos.query';
import * as S from './Productos.styled';

const Productos: FC<AguilaProps> = (props) => {
  const { isMobile } = props;
  const [productoSeleccionado, setProductoSeleccionado] = useState<DetalleProducto>();
  const productos = ProductosQuery();

  const onChangeProducto = (producto: DetalleProducto) => {
    setProductoSeleccionado(producto);
  };

  useEffect(() => {
    if (!productoSeleccionado && productos) {
      const firstProduct = productos[0];
      setProductoSeleccionado(firstProduct);
    }
  }, [productos, productoSeleccionado]);

  if (!productoSeleccionado) {
    return <div>Cargando...</div>;
  }

  if (isMobile) {
    return (
      <SectionContainer id="section-productos" {...props}>
        <SectionTitle {...props} />
        <Row style={{ padding: '0 15px', minHeight: '75vh' }}>
          <Col span={24}>
            <QueueAnim
              type="left"
              ease="easeOutQuart"
              key="product-image"
              leaveReverse
              style={{
                display: 'flex',
                justifyContent: 'center',
                border: '1px solid #e9e9e9',
                borderRadius: '1px',
                backgroundColor: '#f4f4f4',
                margin: '0 3%',
              }}
            >
              <GatsbyImage
                key="product-image-detail"
                style={{ width: '60%' }}
                image={productoSeleccionado.src.childImageSharp.gatsbyImageData}
                alt="informacion que cura"
              />
            </QueueAnim>
          </Col>

          <Col span={24}>
            <ProductosLista {...props} onChange={(producto: DetalleProducto) => onChangeProducto(producto)} productos={productos} />
          </Col>
          <Col span={24}>
            <QueueAnim type="scale" ease="easeOutQuart" key="product-image" leaveReverse>
              <ProductoTitulo key="titulo" {...productoSeleccionado} />
              <ProductoInformacion key="informacion" {...productoSeleccionado} />
            </QueueAnim>
          </Col>
        </Row>
      </SectionContainer>
    );
  }

  return (
    <SectionContainer id="section-productos" {...props}>
      <SectionTitle {...props} />
      <Row style={{ minHeight: '58vh', padding: '0 35px' }}>
        <Col span={8} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <QueueAnim delay={300} type="left" ease="easeOutQuart" key="product-image" leaveReverse>
            <GatsbyImage
              key="product-image-detail"
              style={{ width: '100%' }}
              image={productoSeleccionado.src.childImageSharp.gatsbyImageData}
              alt={productoSeleccionado.nombre}
            />
          </QueueAnim>
        </Col>

        <Col span={16}>
          <QueueAnim type={isMobile ? 'bottom' : 'right'} leaveReverse style={{ top: '40%' }}>
            <ProductoSeleccionado {...props}>
              <S.ProductoContenedor key="product-detail">
                <ProductoTitulo {...productoSeleccionado} />
                <QueueAnim type="right" delay={400} leaveReverse>
                  <ProductoInformacion key="product-info" {...productoSeleccionado} />
                </QueueAnim>
                {productos ? (
                  <ProductosLista {...props} onChange={(producto: DetalleProducto) => onChangeProducto(producto)} productos={productos} />
                ) : (
                  ''
                )}
              </S.ProductoContenedor>
            </ProductoSeleccionado>
          </QueueAnim>
        </Col>
      </Row>
    </SectionContainer>
  );
};

export default Productos;
