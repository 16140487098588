import { useStaticQuery, graphql } from 'gatsby';

const TestimonialsQuery = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allTestimonialsJson {
          nodes {
            description
            name
            profesion
            src {
              childImageSharp {
                gatsbyImageData(height: 250, placeholder: BLURRED)
              }
            }
          }
        }
      }
    `
  );

  return query.allTestimonialsJson.nodes;
};

export default TestimonialsQuery;
