import { Card } from 'antd';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { Events } from '../Eventos';
import { EventsItemContainer } from './EventsItem.styled';

const EventsItem: FC<Events> = ({ src, description }) => {
  return (
    <>
      <EventsItemContainer>
        <Card key="event-card" hoverable bodyStyle={{ padding: 0 }}>
          <GatsbyImage
            objectFit="contain"
            image={src.childImageSharp.gatsbyImageData}
            title={description}
            alt={description}
            style={{
              height: '100%',
            }}
          />
        </Card>
      </EventsItemContainer>
    </>
  );
};

export default EventsItem;
