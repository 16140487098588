import { Row } from 'antd';
import React, { FC } from 'react';
import img from '../../assets/images/statistics/statistics-bg.jpg';
import SectionTitle from '../../shared/components/SectionTitle/SectionTitle';
import SubSectionContainer from '../../shared/components/SubSectionContainer/SubSectionContainer';
import { AguilaProps } from '../../shared/constants/interfaces/aguila-props';
import StatisticsItem from './components/StatisticsItem';
import StatisticsQuery from './Statistics.query';

const Statistics: FC<AguilaProps> = (props) => {
  const statistics = StatisticsQuery();
  const statisticsProps = { ...props, subTitleColor: '#fff' };
  const { isMobile } = props;

  const items = statistics.map((item: StatisticsData) => {
    const statisticsItemProps = { isMobile, ...item, src: item.src.childImageSharp.gatsbyImageData };
    return <StatisticsItem key={item.description} {...statisticsItemProps} />;
  });

  return (
    <SubSectionContainer backgroundImage={img}>
      <SectionTitle {...statisticsProps} />
      <Row className="row-items-container" style={{ minHeight: '20vh', alignItems: 'center' }}>
        {items}
      </Row>
    </SubSectionContainer>
  );
};

export default Statistics;

interface StatisticsData {
  description: string;
  src: any;
  fromNumber: number;
  toNumber: number;
}
